<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit" >
			<template #title>
				<modalHeader :title="isEdit ? '编辑文献分类' : '新增文献分类'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="文献分类" name="name" :rules="[{ required: true, message: '请输入文献分类!' }]">
						<a-input v-model:value="modelRef.name" placeholder="请输入文献分类"></a-input>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import eventBus from '@/utils/eventBus.js';
	import { postUpdate, postSave } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				visible: false,
				isEdit: false,
				isOpen: false,
				modelRef: {}
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					if(!this.modelRef.type) this.modelRef.type = 5
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await postUpdate(postData);
						} else {
							ret = await postSave(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							eventBus.$emit('refreshLiterClassify');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						type: e.type,
						name: e.name,
						code: e.code,
						id: e.id,
						parentId: e.parentId
					}
					this.isEdit = true;
				} else {
					this.modelRef = {};
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.visible = false;
			}
		}
	}
</script>

<style>
</style>