import { send } from "../index";

export function login(data, opts = {}) {
  return send({
    url: "/admin/account/login.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPower(data, opts = {}) {
  return send({
    url: "/admin/adminUser/getPower.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getMenuById(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/listByParentId.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function menuList(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function menuDetail(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function editMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteMenu(data, opts = {}) {
  return send({
    url: "/admin/adminMenu/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getLog(data, opts = {}) {
  return send({
    url: "/admin/adminLog/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function roleList(data, opts = {}) {
  return send({
    url: "/admin/adminRole/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/add.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function roleDetail(data, opts = {}) {
  return send({
    url: "/admin/adminRole/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function editRole(data, opts = {}) {
  return send({
    url: "/admin/adminRole/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRoleMenu(data, opts = {}) {
  return send({
    url: "/admin/adminRole/getMenu.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function setRoleMenu(data, opts = {}) {
    return send({
        url: '/admin/adminRole/editMenu.do',
        method: "POST",
        data,
        ...opts
    })
}


export function userList(data, opts = {}) {
  return send({
    url: "/admin/adminUser/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userDetail(data, opts = {}) {
  return send({
    url: "/admin/adminUser/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userUpdate(data, opts = {}) {
  return send({
    url: "/admin/adminUser/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userAdd(data, opts = {}) {
  return send({
    url: "/admin/adminUser/add.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function userDel(data, opts = {}) {
  return send({
    url: "/admin/adminUser/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function userChangeStatus(data, opts = {}) {
  return send({
    url: "/admin/adminUser/changeStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFeedBack(data, opts = {}) {
  return send({
    url: "/admin/feedback/list.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getFeedBackProcesse(data, opts = {}) {
  return send({
    url: "/admin/feedback/processed.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 公告列表
export function getPlatText(data, opts = {}) {
  return send({
    url: "/admin/platText/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 公告新增 
export function getPlatTextSave(data, opts = {}) {
  return send({
    url: "/admin/platText/save.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 公告修改
export function getPlatTextUpdate(data, opts = {}) {
  return send({
    url: "/admin/platText/update.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 公告删除
export function getPlatTextDelete(data, opts = {}) {
  return send({
    url: "/admin/platText/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDataList(data, opts = {}) {
  return send({
    url: "/admin/adminPower/dataSetting.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDataDimensionList(data, opts = {}) {
  return send({
    url: "/admin/adminPower/dimensionList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 获取参数列表
export function getBasicsParameterList(data, opts = {}) {
  return send({
    url: "/admin/basicsParameter/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 新增资产分类
export function postSave(data, opts = {}) {
  return send({
    url: "/admin/basicsParameter/save.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 修改资产分类
export function postUpdate(data, opts = {}) {
  return send({
    url: "/admin/basicsParameter/update.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 资产分类 四级分类下拉框
// export function getPropertyClass(data, opts = {}) {
//   return send({
//     url: "/admin/basicsParameter/searchListNoPage.do",
//     method: "POST",
//     data,
//     ...opts,
//   });
// }
// 删除参数类型
export function postDelete(data, opts = {}) {
  return send({
    url: "/admin/basicsParameter/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 获取可分配角色
export function getMyAvailableList(data, opts = {}) {
  return send({
    url: "/admin/adminRole/myAvailableList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 提交预警时间
export function postUpdateWarnTime(data, opts = {}) {
  return send({
    url: "/admin/dataDict/updateWarnTime.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 修改用户信息
export function postUpdatePassword(data, opts = {}){
  return send({
    url: "/admin/adminUser/updPassword.do",
    method: "POST",
    data,
    ...opts,
  });
}