<template>
	<div>
		<a-row>
			<a-col :span="18">
				<a-button @click="onSearchBtn">
					<template #icon><Icon icon="SearchOutlined"/></template>
					查询</a-button>
				<!-- <a-button v-permission="['pc_system_options_wayImport']">
					<template #icon><Icon icon="DownloadOutlined"/></template>
					导入</a-button> -->
				<exportModal class="ui-exportBrn" v-permission="['pc_system_options_wayExport']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
					<a-button>
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
				</exportModal>
				<exportModal class="ui-exportBrn" v-permission="['pc_system_options_wayExport']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
					<a-button>
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button>
				</exportModal>
				<!-- <a-button v-permission="['pc_system_options_wayExport']">
					<template #icon><Icon icon="UploadOutlined"/></template>
					导出</a-button>
				<a-button v-permission="['pc_system_options_wayExport']">
					<template #icon><Icon icon="LogoutOutlined"/></template>
					导出全部</a-button> -->
				<a-button @click="onRef">
					<template #icon><Icon icon="ReloadOutlined"/></template>
					刷新</a-button>
				<!-- <a-button @click="onSort">
					<template #icon><Icon icon="OrderedListOutlined"/></template>
					排序</a-button> -->
			</a-col>
			<!-- <a-col :span="6" style="text-align: right;">
				<a-button v-permission="['pc_system_options_wayAdd']" type="primary" @click="onAddWay">新增取得方式</a-button>
			</a-col> -->
			<!-- <a-col :span="6" style="text-align: right;">
				<a-button v-permission="['pc_system_options_wayAdd']" type="primary" @click="onAddWay">��<a-button @click="onSearch">						<template #icon><Icon icon="ReloadOutlined"/></template>						刷新</a-button></a-button>
			</a-col> -->
		</a-row>
		
		<div v-show="isSearch">
			<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
				<a-form-item label="取得方式" class="ui-form__item">
					<a-input v-model:value="formModal.name" placeholder="请输入分类名称"></a-input>
				</a-form-item>
				<a-form-item class="ui-form__item">
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form-item>
			</a-form>
		</div>
		
		<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
			<a-row style="align-items: center;">
				<a-col :span="18">
					<a-checkbox-group>
						<a-checkbox >取得方式</a-checkbox>
					</a-checkbox-group>
				</a-col>
				<a-col :span="6" style='text-align: right;'>
					<a-button type="primary">正序</a-button>
				</a-col>
			</a-row>
		</div>
		
		<div style="margin-top: 20px;">
			<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" bordered draggable>
				<template #bodyCell="{ column, record, index, pagination }">
					<template v-if="column.key === 'index'">
						{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
					</template>
					<!-- <template v-if="column.key === 'action'">
						<a-button v-permission="['pc_system_options_wayEdit']" type="link" size="small" @click="onEditWay(record)">编辑</a-button>
						<span></span>
						<a-button v-permission="['pc_system_options_wayDelete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
					</template> -->
				</template>
			</c-Table>
			
			<!-- <a-table rowKey="id" class="table"
				:columns="columns" 
				:dataSource="list" 
				:pagination="pagination" bordered 
				size="small"
				:scroll="{ x: 1500 }"
			>
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'index'">
						{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
					</template>
					<template v-if="column.key === 'action'">
						<a-button v-permission="['pc_system_options_wayEdit']" type="link" size="small" @click="onEditWay(record)">编辑</a-button>
						<span></span>
						<a-button v-permission="['pc_system_options_wayDelete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
					</template>
				</template>
			</a-table> -->
		</div>
		
		<div>
			<saveAcquireWayModal ref="saveAcquireWayModalRef" @refresh="onReset"></saveAcquireWayModal>
		</div>
	</div>
</template>

<script>
	import { getBasicsParameterList, postDelete } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import saveAcquireWayModal from './saveAcquireWayModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveAcquireWayModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				formModal: {
					type: 2
				},
				searchData: {},
				list: [],
				type: 'argsMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				getDataFun: getBasicsParameterList,
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 100
				}, {
					title: '使用状况',
					align: 'center',
					dataIndex: "name"
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getBasicsParameterList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			type: 2,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("inve",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onRef() {
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.formModal.type = 2
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAddWay() {
				this.$refs.saveAcquireWayModalRef.open();
			},
			onEditWay(item) {
				this.$refs.saveAcquireWayModalRef.open(item);
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await postDelete({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								// this.getData();
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
		
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>