<template>
	<div>
		<a-spin :spinning="loading">
			<a-tabs v-model:activeKey="activeKey">
			    <a-tab-pane :key="1" tab="资产分类"></a-tab-pane>
			    <a-tab-pane :key="2" tab="取得方式"></a-tab-pane>
			    <a-tab-pane :key="3" tab="设备用途"></a-tab-pane>
				<a-tab-pane :key="4" tab="使用状态"></a-tab-pane>
				<a-tab-pane :key="5" tab="文献分类"></a-tab-pane>
			</a-tabs>
			<div v-if="activeKey === 1">
				<propertyClassify></propertyClassify>
			</div>
			<div v-if="activeKey === 2">
				<acquireWay></acquireWay>
			</div>
			<div v-if="activeKey === 3">
				<useClassify></useClassify>
			</div>
			<div v-if="activeKey === 4">
				<statusClassify></statusClassify>
			</div>
			<div v-if="activeKey === 5">
				<literatureClassify></literatureClassify>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import propertyClassify from './components/propertyClassify.vue';
	import acquireWay from './components/acquireWay.vue';
	import useClassify from './components/useClassify.vue';
	import statusClassify from './components/statusClassify.vue';
	import literatureClassify from './components/literatureClassify.vue';
	export default {
		components: { propertyClassify, acquireWay, useClassify, statusClassify, literatureClassify },
		data() {
			return {
				loading: false,
				activeKey: 1,
			}
		},
		methods: {
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
</style>