import {
	send
} from "../index";
// 资产列表
export function getAssetsList(data, opts = {}) { // 财政管理
	return send({
		url: "/admin/assets/searchList.do",
		method: "POST",
		data,
		...opts,
	});
}
// 资产列表 财政 按单位
export function getListByUnit(data, opts = {}) { // 财政管理
	return send({
		url: "/admin/assets/searchListByUnit.do",
		method: "POST",
		data,
		...opts,
	});
}
// 资产列表 管理 按资产类型
export function getListByType(data, opts = {}) { // 财政管理
	return send({
		url: "/admin/assets/searchListByType.do",
		method: "POST",
		data,
		...opts,
	});
}
//资产查询
export function getAssetsDetailSearch(data, opts = {}) {
	return send({
		url: "/admin/assets/search.do",
		method: "POST",
		data,
		...opts,
	});
}
// 资产转移列表
export function updateAssetsUser(data, opts = {}) {
	return send({
		url: "/admin/assets/updateAssetsUser.do",
		method: "POST",
		data,
		...opts,
	});
}
// 借入借出 资产管理
export function getBorrowOrLendListByType(data, opts = {}) {
	return send({
		url: "/admin/assets/borrowOrLendListByType.do",
		method: "POST",
		data,
		...opts,
	});
}
// 我的借入 资产归还 
export function getReturn(data, opts = {}) {
	return send({
		url: "/admin/assets/return.do",
		method: "POST",
		data,
		...opts,
	});
}
// 申请借入借出  
export function applyBorrowOrLend(data, opts = {}) {
	return send({
		url: "/admin/assets/applyBorrowOrLend.do",
		method: "POST",
		data,
		...opts,
	});
}
// 调入调出列表 资产管理  
export function getTransferListByType(data, opts = {}) {
	return send({
		url: "/admin/assets/transferListByType.do",
		method: "POST",
		data,
		...opts,
	});
}
// 申请调入调出 资产管理  
export function applyTransfer(data, opts = {}) {
	return send({
		url: "/admin/assets/applyTransfer.do",
		method: "POST",
		data,
		...opts,
	});
}
// 撤销调出 资产管理  
export function revokeTransfer(data, opts = {}) {
	return send({
		url: "/admin/assets/revokeTransfer.do",
		method: "POST",
		data,
		...opts,
	});
}
// 删除资产
export function deleteAssets(data, opts = {}) {
	return send({
		url: "/admin/assets/delete.do",
		method: "POST",
		data,
		...opts,
	});
}
// 修改资产
export function updateAssets(data, opts = {}) { // 财政管理
	return send({
		url: "/admin/assets/update.do",
		method: "POST",
		data,
		...opts,
	});
}
// 录入资产
export function saveAssets(data, opts = {}) { // 财政管理
	return send({
		url: "/admin/assets/save.do",
		method: "POST",
		data,
		...opts,
	});
}
// 下账列表
export function getAccountsList(data, opts = {}) {
	return send({
		url: "/admin/assets/keepAccountsList.do",
		method: "POST",
		data,
		...opts,
	});
}
// 下账导出
export function printKeepAccounts(data, opts = {}) {
	return send({
		url: "/admin/assets/printKeepAccounts.do",
		method: "POST",
		data,
		...opts,
	});
}
// 澈销下账
export function revokeKeepAccounts(data, opts = {}) {
	return send({
		url: "/admin/assets/revokeKeepAccounts.do",
		method: "POST",
		data,
		...opts,
	});
}
// 下账申请
export function applyKeepAccounts(data, opts = {}) {
	return send({
		url: "/admin/assetsRecord/applyKeepAccounts.do",
		method: "POST",
		data,
		...opts,
	});
}
// 报废列表（资产管理员）
export function getScrapListByType(data, opts = {}) {
	return send({
		url: "/admin/scrap/scrapListByType.do",
		method: "POST",
		data,
		...opts,
	});
}
// 报废申请
export function postApplyScrap(data, opts = {}) {
	return send({
		url: "/admin/assets/applyScrap.do",
		method: "POST",
		data,
		...opts,
	});
}
// 撤销报废申请
export function postRevokeScrap(data, opts = {}) {
	return send({
		url: "/admin/scrap/revokeScrap.do",
		method: "POST",
		data,
		...opts,
	});
}
// 资产转移列表
export function getAssetsTransferList(data, opts = {}) {
	return send({
		url: "/admin/assets/assetsTransferByUserList.do",
		method: "POST",
		data,
		...opts,
	});
}



// 报废列表（财政管理员）
export function scrapListByUnit(data, opts = {}) {
	return send({
		url: "/app/scrap/scrapListByUnit",
		method: "POST",
		data,
		...opts,
	});
}
// 报废导出
export function printScrap(data, opts = {}) {
	return send({
		url: "/app/scrap/printScrap.do",
		method: "POST",
		data,
		...opts,
	});
}
// 撤销报废
export function revokeScrap(data, opts = {}) {
	return send({
		url: "/app/scrap/revokeScrap.do",
		method: "POST",
		data,
		...opts,
	});
}
// 报废申请
export function applyScrap(data, opts = {}) {
	return send({
		url: "/app/assets/applyScrap.do",
		method: "POST",
		data,
		...opts,
	});
}
//报废列表 资产管理员
export function scrapListByType(data, opts = {}) {
	return send({
		url: "/app/scrap/scrapListByType",
		method: "POST",
		data,
		...opts,
	});
}
//借入借出列表 财政管理员
export function getBorrowOrLendListByUnit(data, opts = {}) {
	return send({
		url: "/admin/assets/borrowOrLendListByUnit.do",
		method: "POST",
		data,
		...opts,
	});
}
//报废列表 财政管理员
export function getScrapListByUnit(data, opts = {}) {
	return send({
		url: "/admin/scrap/scrapListByUnit.do",
		method: "POST",
		data,
		...opts,
	});
}
//调入调出列表 财政管理员
export function getTransferListByUnit(data, opts = {}) {
	return send({
		url: "/admin/assets/transferListByUnit.do",
		method: "POST",
		data,
		...opts,
	});
}
//调入调出列表 财政管理员
export function getAssetsCount(data, opts = {}) {
	return send({
		url: "/admin/assets/count.do",
		method: "POST",
		data,
		...opts,
	});
}
//借入确认
export function getConfirmBorrowOrLend(data, opts = {}) {
	return send({
		url: "/admin/assets/confirmBorrowOrLend.do",
		method: "POST",
		data,
		...opts,
	});
}
//资产归还确认
export function getReturnConfirm(data, opts = {}) {
	return send({
		url: "/admin/assets/returnConfirm.do",
		method: "POST",
		data,
		...opts,
	});
}

//确认调入调出 财政
export function postConfirmTransfer(data, opts = {}) {
	return send({
		url: "/admin/assets/confirmTransfer.do",
		method: "POST",
		data,
		...opts,
	});
}
//资产分类
export function getAssetsClassify(data, opts = {}) {
	return send({
		url: "/admin/assets/assetsClassify.do",
		method: "POST",
		data,
		...opts,
	});
}
//新资产分类
export function getAssetsClassifyNode(data, opts = {}) {
	return send({
		url: "/admin/assets/classifyNode.do",
		method: "POST",
		data,
		...opts,
	});
}
