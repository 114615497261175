<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑资产分类' : '新增资产分类'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="资产分类名称" name="name" :rules="[{ required: true, message: '请输入资产分类名称!' }]">
						<a-input v-model:value="modelRef.name" placeholder="请输入资产分类名称"></a-input>
					</a-form-item>
					
					<a-form-item label="资产分类编号" name="code" :rules="[{ required: true, message: '请输入资产分类编号!' }]">
						<a-input v-model:value="modelRef.code" placeholder="请输入资产分类编号"></a-input>
					</a-form-item>
					
					<a-form-item label="是否一级分类">
						<a-radio-group v-model:value="isParent">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<div v-if="isParent === 0">
						<a-form-item label="一级分类" name="id1">
							<a-select v-model:value="tempModelRef.id1" placeholder="请选择一级分类">
								<a-select-option v-for="(item, index) in parentList" :key="item.id" :value="item.id" @click="getParentFun(item,2)">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						<!-- <a-form-item label="一级分类">
							<a-select v-model:value="
							.parentId" placeholder="请选择一级分类">
								<a-select-option v-for="(item, index) in parentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item> -->
						
						<a-form-item label="二级分类" name="id2">
							<a-select v-model:value="tempModelRef.id2" placeholder="请选择二级分类">
								<a-select-option v-for="(item, index) in twoList" :key="item.id" :value="item.id" @click="getParentFun(item,3)">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item label="三级分类" name="id3">
							<a-select v-model:value="tempModelRef.id3" placeholder="请选择三级分类">
								<a-select-option v-for="(item, index) in threeList" :key="item.id" :value="item.id" @click="getParentFun(item,4)">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<!-- <a-form-item label="四级分类">
							<a-select placeholder="请选择四级分类">
								<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item> -->
					</div>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import eventBus from '@/utils/eventBus.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import { postUpdate, postSave } from '@/service/modules/system.js';
	import { getAssetsClassifyNode } from '@/service/modules/property.js';
	import { getAllClassifyList } from '@/service/modules/global.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				visible: false,
				isEdit: false,
				isOpen: false,
				// searchData: {
				// 	type: 1,
				// 	level: undefined,
				// },
				// level: null,
				// pid: null,
				parentList: [],
				twoList: [],
				threeList: [],
				// fourList: [],
				isParent: 1,
				// assetClass: [], // 二级资产分类列表
				modelRef: {},
				tempModelRef: {},
			}
		},
		methods: {
			async getAssetsClassifyNode(id) {
				this.loading = true;
				let ret = await getAssetsClassifyNode({
					id
				});
				this.loading = false;
				if (ret.code === 200) {
					let data = ret.data.reverse();
					if (data.length) {
						this.isParent = 0;
						this.getAssetClassify(1);
						this.tempModelRef.id1 = data[0];
						if (data.length >= 2) {
							this.getAssetClassify(2, data[0]);
							this.tempModelRef.id2 = data[1];
						}
						if (data.length >= 3) {
							this.getAssetClassify(3, data[1]);
							this.tempModelRef.id3 = data[2];
						}
					}
				}
			},
			async getAssetClassifyAll() {
				let ret = await getAllClassifyList({
					type: 1
				});
				if (ret.code === 200) {
				}
			},
			async getAssetClassify(levelNum,pid) {
				let ret = await getAllClassifyList({
					type: 1,
					level: levelNum,
					parentId: pid
				});
				if (ret.code === 200) {
					switch(levelNum) {
						case 1:
							this.parentList = ret.data;
							break;
						case 2:
							this.twoList = ret.data;
							break;
						case 3:
							this.threeList = ret.data;
							break;	
					}
					// this.pid = ret.data[0].parentId
					// console.log("pid",this.pid)
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					if(!this.modelRef.type) this.modelRef.type = 1
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await postUpdate(postData);
						} else {
							ret = await postSave(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							eventBus.$emit('refreshAssetClassify');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			getParentFun(item,index) {
				let newLevel = item.level
				if (index === 2) {
					this.tempModelRef.id2 = undefined;
				}
				if (index === 2 || index === 3) {
					this.tempModelRef.id3 = undefined;
				}
				this.modelRef.parentId = item.id
				this.modelRef.level = newLevel
				this.getAssetClassify(index,item.id)
			},
			// async getDetail() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getMenuDetail({
			// 			id: this.id
			// 		})
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			delete ret.data.group;
			// 			let groupList = ret.data.routing?.split(',') || [];
			// 			groupList.forEach((code, index) => {
			// 				if (index === 0) {
			// 					ret.data.groupOneCode = code;
			// 					this.onGroupOneChange(code);
			// 				}
			// 				if (index === 1) {
			// 					ret.data.groupTwoCode = code;
			// 					this.onGroupTwoChange(code);
			// 				}
			// 				if (index === 2) {
			// 					ret.data.groupThireCode = code;
			// 					this.onGroupThireChange(code);
			// 				}
			// 				if (index === 3) {
			// 					ret.data.groupFourCode = code;
			// 					this.onGroupFourChange(code);
			// 				}
			// 				if (index === 4) {
			// 					ret.data.groupFiveCode = code;
			// 				}
			// 			})
			// 			this.modelRef = ret.data;
			// 		}
			// 	} catch(err) {
			// 		this.loading = false;
			// 	}
			// },
			async open(e) {
				if (e && e.id) {
					this.isParent = 0;
					this.modelRef = {
						type: e.type,
						name: e.name,
						code: e.code,
						id: e.id,
						parentId: e.parentId,
						level: e.level
					}
					this.getAssetsClassifyNode(e.id);
					let tempLevel = e.level
					let tempPid = e.parentId
					// while(tempLevel > 0) {
					// 	await this.getAssetClassify(tempLevel-1,this.pid)
					// 	console.log("temp")
					// 	tempLevel--
					// }
					// this.getAssetClassify(1)
					// this.getAssetClassify(2)
					// this.getAssetClassify(3)
					this.isEdit = true;
				} else {
					this.isParent = 1
					this.modelRef = {};
					this.getAssetClassify(1)
					// this.getAssetClassifyAll()
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.modelRef = {}
				this.tempModelRef = {}
				this.parentList = []
				this.twoList = []
				this.threeList = []
				// this.isParent = 1
				this.visible = false;
			}
		}
	}
</script>

<style>
</style>